<template>
  <div class="center-main pdHeader">
    <common-header class="orange"></common-header>
    <!-- content start -->

    <div class="phone-step" v-if="step !== 3">
      <div class="flex steps tc">
        <div :class="`fe item ${step === 1 ? 'active' : ''}`">
          <b class="num">1</b>
          <span class="text">验证当前手机</span>
        </div>
        <div :class="`fe item ${step === 2 ? 'active' : ''}`">
          <b class="num">2</b>
          <span class="text">设置新手机</span>
        </div>
        <div :class="`fe item ${step === 3 ? 'active' : ''}`">
          <b class="num">3</b>
          <span class="text">完成</span>
        </div>
      </div>
    </div>
    <!-- 表单1 start -->
    <div class="forms" v-if="step === 1">
      <div class="form-item">
        <div class="name-box">
          <span class="name">原手机号码</span>
          <em class="required">（必填）</em>
        </div>
        <div class="input-box">
          <input
            type="text"
            placeholder="请输入原手机号码"
            maxlength="11"
            class="input"
            v-model="phone"
            readonly
            @blur="fixScroll"
          />
        </div>
      </div>

      <div class="form-item">
        <div class="name-box">
          <span class="name">验证码</span>
          <em class="required">（必填）</em>
        </div>
        <div class="input-box flex centre3">
          <div class="fe">
            <input
              type="text"
              placeholder="请输入验证码"
              class="input"
              v-model="verifyCode"
              maxlength="6"
              @blur="fixScroll"
            />
          </div>
          <div @click="send">
            <span :class="`yzm ${verifyClass}`">{{ verifyText }}</span>
          </div>
        </div>
      </div>

      <div class="form-btns">
        <a href="javascript:" class="form-item-btn" @click="stepHandle"
          >下一步</a
        >
      </div>
    </div>

    <!-- 表单1 end -->

    <!-- 表单2 start -->
    <div class="forms" v-else-if="step === 2">
      <div class="form-item">
        <div class="name-box">
          <span class="name">新手机号码</span>
          <em class="required">（必填）</em>
        </div>
        <div class="input-box">
          <input
            type="text"
            placeholder="请输入手机号码"
            maxlength="11"
            class="input"
            v-model="phone"
            @blur="fixScroll"
          />
        </div>
      </div>

      <div class="form-item">
        <div class="name-box">
          <span class="name">验证码</span>
          <em class="required">（必填）</em>
        </div>
        <div class="input-box flex centre3">
          <div class="fe">
            <input
              type="text"
              placeholder="请输入验证码"
              class="input"
              v-model="verifyCode"
              maxlength="6"
              @blur="fixScroll"
            />
          </div>
          <div @click="send">
            <span :class="`yzm ${verifyClass}`">{{ verifyText }}</span>
          </div>
        </div>
      </div>

      <div class="form-btns">
        <a href="javascript:" class="form-item-btn" @click="submit">下一步</a>
      </div>
    </div>

    <!-- 表单2 end -->

    <!-- 完成 start -->
    <div class="phone-finish tc" v-else-if="step === 3">
      <div class="img-box">
        <i class="phone-finish-img"></i>
      </div>
      <div class="text">恭喜您，修改成功</div>

      <router-link to="/account" class="form-item-btn"
        >返回个人中心</router-link
      >
    </div>
    <!-- 完成 end -->
    <!-- content end -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { isPhone, isCode, fixScroll } from "@/utils/utils";
import { getPreKey } from "@/utils/codeVerify";
import { setCookies } from "@/utils/common";

let timer = 60;
let inter;
export default {
  name: "UpdatePhone",
  data() {
    this.fixScroll = () => fixScroll();

    return {
      // 验证码需要 start
      verifyClass: "",
      verifyText: "获取验证码",
      phone: JSON.parse(this.$Cookies.get("user")).phone,
      verifyCode: "",
      // 验证码需要 end
      step: 1
    };
  },
  mounted() {
    console.log();
  },
  methods: {
    ...mapActions("account", ["getValidate", "modifyPhone"]),
    // 获取验证码 start
    send() {
      if (this.verifyClass === "disabled") {
        return;
      }

      this.sendTimer();
      inter = setInterval(this.sendTimer, 1000);
      let type = this.step === 1 ? 6 : 3;

      getPreKey(
        {
          phone: this.phone,
          type: type
        },
        v => {
          console.log(v);
          this.verifyCode = v.verifyCode;
        },
        () => {
          this.clearTimer();
        }
      );
    },
    clearTimer() {
      timer = 60;
      this.verifyClass = "";
      this.verifyText = "获取验证码";
      clearInterval(inter);
    },
    sendTimer() {
      timer--;
      if (timer < 1) {
        this.verifyClass = "";
        this.verifyText = "获取验证码";
        clearInterval(inter);
      } else {
        this.verifyClass = "disabled";
        this.verifyText = timer + "s";
      }
    },
    // 获取验证码 end
    stepHandle() {
      if (this.phone === "") {
        this.$toast("手机号码不能为空");
        return;
      }

      if (!isPhone(this.phone)) {
        this.$toast("手机号码格式不正确");
        return;
      }

      if (this.verifyCode === "") {
        this.$toast("验证码不能为空");
        return;
      }
      if (!isCode(this.verifyCode)) {
        this.$toast("验证码格式不正确");
        return;
      }

      this.getValidate({
        code: this.verifyCode,
        phone: this.phone,
        type: 6
      }).then(() => {
        this.step = 2;

        this.clearTimer();
        this.verifyCode = "";
        this.phone = "";
      });
    },
    submit() {
      if (this.phone === "") {
        this.$toast("手机号码不能为空");
        return;
      }

      if (!isPhone(this.phone)) {
        this.$toast("手机号码格式不正确");
        return;
      }

      const da = {
        phone: this.phone,
        verifyCode: this.verifyCode
      };

      this.modifyPhone(da).then(res => {
        this.step = 3;
        // 设置cook
        setCookies(res.data);
      });
    }
  }
};
</script>
<style lang="less" scoped>
.phone-finish {
  padding: 1.4rem 0.4rem 0.4rem 0.4rem;
}
.phone-finish-img {
  width: 2.66rem;
  height: 2.51rem;
  background: url("../../assets/images/img/success-icon.png") no-repeat top
    center;
  background-size: 100% auto;
  display: inline-block;
}

.text {
  font-size: 0.36rem;
  color: rgba(255,113,65,1);
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
}

.phone-step {
  // background-color: #fe4d3b;
  background-color: rgba(255,113,65,1);
  height: 2.4rem;
  color: #fff;
  padding: 0.7rem 0.2rem 0 0.2rem;
}

.steps {
  .item {
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      width: 50%;
      height: 2px;
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 0.3rem;
      z-index: 1;
    }
    &::after {
      content: "";
      width: 50%;
      height: 2px;
      background-color: #fff;
      position: absolute;
      right: 0;
      top: 0.3rem;
      z-index: 1;
    }
  }

  .item:first-child::before {
    width: 0;
  }

  .item:last-child::after {
    width: 0;
  }

  .num {
    color: #ff9389;
    background-color: #ffb8b1;
    width: 0.65rem;
    height: 0.65rem;
    line-height: 0.65rem;
    display: inline-block;
    border-radius: 100%;
    font-size: 0.36rem;
    font-weight: bold;
    font-style: italic;
    position: relative;
    z-index: 2;
  }

  .text {
    color: rgba(254, 254, 254, 0.5);
    display: block;
    margin-top: 0.4rem;
  }

  .item.active .num {
    color: rgba(255,113,65,1);
    background-color: rgba(255, 255, 255, 1);
  }
  .item.active .text {
    color: rgba(254, 254, 254, 1);
  }
}
.forms {
  padding: 0.6rem 0.3rem;
}
</style>
